/*eslint-disable*/
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Email from "@material-ui/icons/Email";
import Favorite from "@material-ui/icons/Favorite";
import Face from "@material-ui/icons/Face";
import Phone from "@material-ui/icons/Phone";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import InputLabel from "@material-ui/core/InputLabel"

import loginPageStyle from "assets/jss/material-kit-pro-react/views/loginPageStyle.js";

//import image from "assets/img/bg7.jpg";
import image from "assets/img/Backdrop-extreme.jpg"
import logoImg from "assets/img/Extreme-Dance-Competition-Logo.png"
import CardFooter from "../../components/Card/CardFooter";

const useStyles = makeStyles(loginPageStyle);


const CheckIn = (props) => {
    React.useEffect(() => {
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        console.log()
    });
    const [form, setForm] = React.useState({
        checkInName: "Extreme Dance Comp",
        checkInLocation: "Narrabeen Sports High",
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        personnelType: "",
        area: "",
        checkInTime: null
    })

    const [checkedIn, setCheckedIn] = React.useState(false)

    const submit = () => {
        fetch(window.location.protocol + "//" + window.location.hostname+ '/api/checkin', {
            method: 'POST', // or 'PUT'
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({...form, fullName: form.firstName + " " + form.lastName}),
        })
            .then(response => {
                console.log('Success:', response);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json()
            })
            .then(data => {
                console.log('Success:', data);
                if (data.success) {
                    console.log('Success:', data);
                    setCheckedIn(true)
                    setForm((data)=> {return {...data, checkInTime: new Date}})
                }
                else {
                    console.log('Error:', data);
                }

            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }



    const classes = useStyles();
    return (
        <div>
            <div
                className={classes.pageHeader}
                style={{
                    backgroundImage: "url(" + image + ")",
                    backgroundSize: "cover",
                    backgroundPosition: "top center"
                }}
            >
                <div className={classes.container}>
                    <GridContainer justify="center">
                        <GridItem xs={12} sm={12} md={6}>
                            {checkedIn? <CheckedIn data={form}/>: null}
                            <Card>
                                <form className={classes.form}>
                                    <CardHeader
                                        color="primary"
                                        signup
                                        className={classes.cardHeader}
                                    >
                                        <img src={logoImg} width={150} />
                                        {/*<h3 className={classes.cardTitle}>Extreme Dance Comp</h3>*/}
                                        <h5>{form.checkInLocation}</h5>
                                    </CardHeader>
                                        <div style={{width:"80%", margin: "auto"}}>
                                            <p className={classes.description + " " + classes.textCenter}>
                                            Here is some bolierplate legal jargon which will talk about the privacy and terms of this site
                                                <p/>
                                                Lorem ipsum dolor sit amet, vel aptent molestie ornare nulla, et praesent diam adipiscing sed, est aptent est donec lacus. Integer eu eros massa donec, malesuada vel leo sit.
                                                <p/>
                                                Tincidunt orci lobortis vulputate mauris, eget volutpat nisi nisi, ullamcorper cras commodo dui, luctus et amet elit dolor. Vitae pellentesque fringilla tempor fusce, non sed malesuada sed urna, et dui placerat per, proin nostra eu sagittis.
                                            </p>
                                        </div>
                                        <CardBody signup>
                                            <GridContainer justify="center">
                                            <GridItem xs={12} sm={12} md={6}>
                                                <CustomInput
                                                    id="first"
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                    inputProps={{
                                                        placeholder: "First Name...",
                                                        type: "text",
                                                        value: form.firstName,
                                                        onChange: (e)=>setForm({...form, firstName: e.target.value}),
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <Face className={classes.inputIconsColor} />
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                />
                                            </GridItem>
                                            <GridItem xs={12} sm={12} md={6}>
                                                <CustomInput
                                                    id="last"
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                    inputProps={{
                                                        placeholder: "Last Name...",
                                                        type: "text",
                                                        value: form.lastName,
                                                        onChange: (e)=>setForm({...form, lastName: e.target.value}),
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <Face className={classes.inputIconsColor} />
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                />
                                            </GridItem>
                                                <GridItem xs={12} sm={12} md={12}>
                                                    <div>
                                                        <InputLabel id="personnelType-Label"></InputLabel>
                                                        <Select
                                                            labelId="personnelType-Label"
                                                            id="personnelType"
                                                            value={form.personnelType}
                                                            fullWidth
                                                            onChange={(event)=>{setForm((state)=>{
                                                                return{
                                                                    ...state,
                                                                    personnelType: event.target.value
                                                                }})}}
                                                        >
                                                            <MenuItem value={"competitor"}>Competitor</MenuItem>
                                                            <MenuItem value={"patron"}>Patron</MenuItem>
                                                            <MenuItem value={"staff"}>Staff</MenuItem>
                                                        </Select>
                                                    </div>
                                                    <CustomInput
                                                        id="email"
                                                        formControlProps={{
                                                            fullWidth: true
                                                        }}
                                                        inputProps={{
                                                            placeholder: "Email...",
                                                            type: "email",
                                                            value: form.email,
                                                            onChange: (e)=>setForm({...form, email: e.target.value}),
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <Email className={classes.inputIconsColor} />
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                    />
                                                        <div className={classes.description + " " + classes.textCenter}>
                                                            Or
                                                        </div>
                                                    <CustomInput
                                                        id="phone"
                                                        formControlProps={{
                                                            fullWidth: true
                                                        }}
                                                        inputProps={{
                                                            placeholder: "Phone...",
                                                            type: "phone",
                                                            value: form.phone,
                                                            onChange: (e)=>setForm({...form, phone: e.target.value}),
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <Phone className={classes.inputIconsColor} />
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                    />
                                                    </GridItem>
                                                </GridContainer>
                                            </CardBody>
                                            <div className={classes.textCenter}>
                                                <Button simple color="primary" size="lg" onClick={submit}>
                                                    Check In
                                                </Button>
                                            </div>


                                </form>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </div>
                <Footer
                    className={classes.footer}
                    content={
                        <div>
                            <div className={classes.left}>
                                <List className={classes.list}>
                                    <ListItem className={classes.inlineBlock}>
                                        <a
                                            href="https://www.creative-tim.com/?ref=mkpr-login"
                                            target="_blank"
                                            className={classes.block}
                                        >
                                            Creative Tim
                                        </a>
                                    </ListItem>
                                    <ListItem className={classes.inlineBlock}>
                                        <a
                                            href="https://www.creative-tim.com/presentation?ref=mkpr-login"
                                            target="_blank"
                                            className={classes.block}
                                        >
                                            About us
                                        </a>
                                    </ListItem>
                                    <ListItem className={classes.inlineBlock}>
                                        <a
                                            href="//blog.creative-tim.com/"
                                            className={classes.block}
                                        >
                                            Blog
                                        </a>
                                    </ListItem>
                                    <ListItem className={classes.inlineBlock}>
                                        <a
                                            href="https://www.creative-tim.com/license?ref=mkpr-login"
                                            target="_blank"
                                            className={classes.block}
                                        >
                                            Licenses
                                        </a>
                                    </ListItem>
                                </List>
                            </div>
                            {/*<div className={classes.right}>
                                &copy; {1900 + new Date().getYear()} , made with{" "}
                                <Favorite className={classes.icon} /> by{" "}
                                <a
                                    href="https://www.creative-tim.com?ref=mkpr-login"
                                    target="_blank"
                                >
                                    Creative Tim
                                </a>{" "}
                                for a better web
                            </div>*/}
                        </div>
                    }
                />
            </div>
        </div>
    );
}

const CheckedIn = (props)=> {
    const classes = useStyles();
    const {data} = props
    return (
        <div>
            <Card>
                <CardHeader
                    color="primary"
                    signup
                    className={classes.cardHeader}>
                    Checked Status
                </CardHeader>
                <CardBody>{data.firstName + " " + data.lastName} has checked in as a {data.personnelType} at {Date(data.checkInTime).toString()}</CardBody>
            </Card>
            <br>

            </br>
        </div>
    )
}

export default CheckIn